import axios from "@/config/axios";

const initialState = () => {
  return {
    pageSize : 0,
    currentPage: null,
    allEntities: null,
    entityCreatedStatus: false,
    entityCreatedData: null,
    entitySettings: null,
    entityDataById: null,
    entityById: null,
    childEntityDataById: null,
    entityDataUpdateStatus: false,
    selectedEntityUsers: null,
    entityDataCreatedStatus: false,
    entityDataCreatedData: null,
    entitySendStatus: false,
    entitySendData: null,
    createdEntityDataById: null,
    createdEntityStatus: false,
    currentUserStatus: null,
    entityCreatedDataUpdateStatus: false,
    viewEntityData: null,
    entityDeleteStatus: false,
    entityErrors: {},
    entityDeleteError: "",
    newEntityUser: null,
    entityDataByEntityId: {},
    entityDataByQuery: null,
    entitiesDataByQuery: null,
    companyEntityDetails: null,
    addEntityToCompanyStatus: false,
    entityDocumentsData: null,
    entityDocumentsDataStatus: false,
    entityDocumentsNotIncludingTemplatesData: null,
    entityDocumentsNotIncludingTemplatesStatus: false,
    employeeDocumentsByEntityIdAndConfigurableDocumentIdData: null,
    employeeDocumentsByEntityIdAndConfigurableDocumentIdStatus: false,
    templatesFieldsDataFromEntitiesData: null,
    templatesFieldsDataFromEntitiesStatus: false,
    entityRecordsForTable: [],
    entityPrimaryData: {},
    errors: {},
    templateRelatedEntities: [],
    documentUploadToEntityProfileStatus: false,
    documentUploadToEntityProfileData: null,
    customDashboardStats: null,
    customDashboardPieStats: null,
    entityDataExportStatus: false,
    entityDataExportPDFStatus : null,
    entityViewsPDFExport: null,
    allEntityDataExportStatus: false,
    uploadCSVfileStatus: null,
    storeUploadedFileStatus: null,
    updateMappedFieldStatus: false,
    mappedFieldsData: null,
    templatesByMappedFields: null,
    importMappedDataStatusUpdatedVersion: false,
    createEntityRecordWithMappedData: false,
    excelFileHeaders: null,
    excelFileHeadersApplication: null,
    uploadedFilesData: null,
    entitydataBulkDeleteStatus: null,
    allCalendarsData: null,
    entitydataBulkDeleteErrors: null,
    entityShowFields: null,
    //entity notificatons
    sentEntityNotificationStatus: false,
    sentEntityDataNotificationStatus: false,
    sentEntityDataNotificationStatusErrors: "",
    bulkUpdateStatus: false,
    entityDataExists: null,
    entityViewsData: null,
    entityByNames:null,
    entityDataByIdError: null,
    entityFieldRelatedEntities: null,
    entityFieldsInputTypes : null,
    entityPrimaryLabels: {},
    allEntitiesInTemplate: {},
    companyEntity: null,
    entitiesDatasByQuery: null,
  };
};

const state = initialState();

const getters = {
  getPageSize : (state) => state.pageSize,
  getCurrentPage: (state) => state.currentPage,
  getentitydataBulkDeleteStatus: (state) => state.entitydataBulkDeleteStatus,
  getEntitydataBulkDeleteErrors: (state) => state.entitydataBulkDeleteErrors,
  getuploadedFilesData: (state) => state.uploadedFilesData,
  getExcelFileHeaders: (state) => state.excelFileHeaders,
  getExcelFileHeadersApplication: (state) => state.excelFileHeadersApplication,
  getimportMappedDataStatusUpdatedVersion: (state) =>
    state.importMappedDataStatusUpdatedVersion,
  getCreateEntityRecordWithMappedData: (state) =>
    state.createEntityRecordWithMappedData,
  getmappedFieldsData: (state) => state.mappedFieldsData,
  gettemplatesByMappedFields: (state) => state.templatesByMappedFields,
  getupdateMappedFieldStatus: (state) => state.updateMappedFieldStatus,
  getstoreUploadedFileStatus: (state) => state.storeUploadedFileStatus,
  getUploadCSVfileStatus: (state) => state.uploadCSVfileStatus,
  getEntityDataExportStatus: (state) => state.entityDataExportStatus,
  getAllEntityDataExportStatus: (state) => state.allEntityDataExportStatus,
  getEntityDataExportPDFStatus: (state) => state.entityDataExportPDFStatus,
  getEntityViewsPDFExport: (state) => state.entityViewsPDFExport,
  getAllEntities: (state) => state.allEntities,
  getEntityCreatedStatus: (state) => state.entityCreatedStatus,
  getEntityCreatedData: (state) => state.entityCreatedData,
  getEntitySettings: (state) => state.entitySettings,
  getEntityDataById: (state) => state.entityDataById,
  getChildEntityDataById: (state) => state.childEntityDataById,
  getEntityById: (state) => state.entityById,

  getCompanyEntity: (state) => state.companyEntity,
  getEntityDataUpdateStatus: (state) => state.entityDataUpdateStatus,
  getSelectedEntityUsers: (state) => state.selectedEntityUsers,
  getEntityDataCreatedStatus: (state) => state.entityDataCreatedStatus,
  getEntityDataCreatedData: (state) => state.entityDataCreatedData,
  getEntitySendStatus: (state) => state.entitySendStatus,
  getEntitySendData: (state) => state.entitySendData,
  getCreatedEntityDataById: (state) => state.createdEntityDataById,
  getCreatedEntityStatus: (state) => state.createdEntityStatus,
  getCurrentUserStatus: (state) => state.currentUserStatus,
  getEntityCreatedDataUpdateStatus: (state) =>
    state.entityCreatedDataUpdateStatus,
  getViewEntityData: (state) => state.viewEntityData,
  getEntityErrors: (state) => state.entityErrors,
  getEntityDeleteStatus: (state) => state.entityDeleteStatus,
  getEntityDeleteError: (state) => state.entityDeleteError,
  getNewEntityUser: (state) => state.newEntityUser,
  getEntityDataByEntityId: (state) => state.entityDataByEntityId,
  getEntityDataByQuery: (state) => state.entityDataByQuery,
  getEntitiesDataByQuery: (state) => state.entitiesDataByQuery,
  getEntitiesDatasByQuery: (state) => state.entitiesDatasByQuery,
  getCronForAutoLogout: (state)=> state.CronForAutoLogout,
  getCompanyEntityDetails: (state) => state.companyEntityDetails,
  getAddEntityToCompanyStatus: (state) => state.addEntityToCompanyStatus,
  getEntityDocumentsData: (state) => state.entityDocumentsData,
  getEntityDocumentsDataStatus: (state) => state.entityDocumentsDataStatus,
  getEntityDocumentsNotIncludingTemplatesData: (state) =>
    state.entityDocumentsNotIncludingTemplatesData,
  getEntityDocumentsDataNotIncludingTemplatesStatus: (state) =>
    state.entityDocumentsDataNotIncludingTemplatesStatus,
  getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData: (state) =>
    state.employeeDocumentsByEntityIdAndConfigurableDocumentIdData,
  getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdStatus: (state) =>
    state.employeeDocumentsByEntityIdAndConfigurableDocumentIdStatus,
  getTemplatesFieldsDataFromEntitiesData: (state) =>
    state.templatesFieldsDataFromEntitiesData,
  getTemplatesFieldsDataFromEntitiesStatus: (state) =>
    state.templatesFieldsDataFromEntitiesStatus,
  getEntityRecordsForTable: (state) => state.entityRecordsForTable,
  getEntityPrimaryData: (state) => state.entityPrimaryData,
  getEntitiesDataErrors: (state) => state.errors,
  getTemplateRelatedEntities: (state) => state.templateRelatedEntities,
  getDocumentUploadToEntityProfileStatus: (state) =>
    state.documentUploadToEntityProfileStatus,
  getDocumentUploadToEntityProfileData: (state) =>
    state.documentUploadToEntityProfileData,
  getCustomDashboardStats: (state) => state.customDashboardStats,
  geCustomDashboardPieStats: (state) => state.customDashboardPieStats,
  getallsetfetchCheckin: (state) => state.allsetfetchCheckin,
  getAllCalendarsData: (state) => state.allCalendarsData,
  getEntityShowFields: (state) => state.entityShowFields,
  getSentEntityNotificationStatus: (state) =>
    state.sentEntityNotificationStatus,
  getSentEntityDataNotificationStatus: (state) =>
    state.sentEntityDataNotificationStatus,
  getSentEntityNotificationStatusErrors: (state) =>
    state.sentEntityNotificationStatusErrors,
  getBulkUpdateStatus: (state) => state.bulkUpdateStatus,
  getEntityDataExists: (state) => state.entityDataExists,
  getEntityViewsData: (state) => state.entityViewsData,
  getEntityByNames: state => state.entityByNames,
  getEntityDataByIdError: state => state.entityDataByIdError,
  getEntityFieldRelatedEntities: state => state.entityFieldRelatedEntities,
  getEntityVariableFieldsInputTypes : state => state.entityFieldsInputTypes,
  getEntityPrimaryLabels: state => state.entityPrimaryLabels,
  getAllEntitiesInTemplate: state => state.allEntitiesInTemplate
};
const mutations = {
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  setPageSize(state, page) {
    state.pageSize = page;
  },
  setentitydataBulkDeleteStatus: (state, data) => {
    state.entitydataBulkDeleteStatus = data;
  },
  setEntitydataBulkDeleteErrors: (state, error) => {
    state.entitydataBulkDeleteErrors = error;
  },
  setuploadedFilesData: (state, data) => {
    state.uploadedFilesData = data;
  },
  setExcelFileHeaders: (state, data) => {
    state.excelFileHeaders = data;
  },
  setExcelFileHeadersApplication: (state, data) => {
    state.excelFileHeadersApplication = data;
  },
  setimportMappedDataStatusUpdatedVersion: (state, status) => {
    state.importMappedDataStatusUpdatedVersion = status;
  },
  setCreateEntityRecordWithMappedData: (state, status) => {
    state.createEntityRecordWithMappedData = status;
  },
  setmappedFieldsData: (state, data) => {
    state.mappedFieldsData = data;
  },
  settemplatesByMappedFields: (state, data) => {
    state.templatesByMappedFields = data;
  },
  setupdateMappedFieldStatus: (state, status) => {
    state.updateMappedFieldStatus = status;
  },
  setstoreUploadedFileStatus: (state, status) => {
    state.storeUploadedFileStatus = status;
  },
  setUploadCSVfileStatus: (state, status) => {
    state.uploadCSVfileStatus = status;
  },
  setEntityDataExportStatus(state, status) {
    state.entityDataExportStatus = status;
  },
  setEntityDataExportPDFStatus(state, status){
    state.entityDataExportPDFStatus = status
  },
  setEntityViewsPDFExport(state, status){
    state.entityViewsPDFExport = status
  },
  setAllEntityDataExportStatus(state, status) {
    state.AllEntityDataExportStatus = status;
  },
  setEntitySendStatus: (state, status) => {
    state.entitySendStatus = status;
  },
  setEntitySendData: (state, data) => {
    state.entitySendData = data;
  },
  setEntityDataCreatedStatus: (state, status) => {
    state.entityDataCreatedStatus = status;
  },
  setEntityDataCreatedData: (state, data) => {
    state.entityDataCreatedData = data;
  },
  setSelectedEntityUsers: (state, data) => {
    state.selectedEntityUsers = data;
  },
  setEntityDataUpdateStatus: (state, status) => {
    state.entityDataUpdateStatus = status;
  },
  setEntityDataById: (state, data) => {
    state.entityDataById = data;
  },

  setChildEntityDataById: (state, data) => {
    state.childEntityDataById = data;
  },
  setEntityById: (state, data) => {
    state.entityById = data;
  },
  setCompanyEntity: (state, data) => {
    state.companyEntity = data;
  },
  setEntitySettings: (state, data) => {
    state.entitySettings = data;
  },
  setAllEntities: (state, data) => {
    state.allEntities = data;
  },
  setEntityCreatedData: (state, data) => {
    state.entityCreatedData = data;
  },
  setEntityCreatedStatus: (state, status) => {
    state.entityCreatedStatus = status;
  },
  setCreatedEntityDataById: (state, data) => {
    state.createdEntityDataById = data;
  },
  setCreatedEntityStatus: (state, status) => {
    state.createdEntityStatus = status;
  },
  setCurrentUserStatus: (state, data) => {
    state.currentUserStatus = data;
  },
  setEntityCreatedDataUpdateStatus: (state, status) => {
    state.entityCreatedDataUpdateStatus = status;
  },
  setViewEntityData: (state, data) => {
    state.viewEntityData = data;
  },
  setEntityDeleteStatus(state, data) {
    state.entityDeleteStatus = data;
  },
  setEntityErrors(state, data) {
    state.entityErrors = data;
  },
  setEntityDeleteError(state, data) {
    state.entityDeleteError = data;
  },
  setNewEntityUser(state, data) {
    state.newEntityUser = data;
  },
  setEntityDataByEntityId(state, data) {
    state.entityDataByEntityId = data;
  },
  setEntityDataByQuery(state, data) {
    state.entityDataByQuery = data;
  },
  setEntitiesDataByQuery(state, data) {
    state.entitiesDataByQuery = data;
  },
  setEntitiesDatasByQuery(state, data) {
    state.entitiesDatasByQuery = data;
  },
  setCronForAutoLogout(state, data){
    state.CronForAutoLogout =data;
  },
  setCompanyEntityDetails(state, data) {
    state.companyEntityDetails = data;
  },
  setAddEntityToCompanyStatus(state, data) {
    state.addEntityToCompanyStatus = data;
  },
  setEntityDocumentsData(state, data) {
    state.entityDocumentsData = data;
  },
  setEntityDocumentsDataStatus(state, status) {
    state.entityDocumentsDataStatus = status;
  },
  setEntityDocumentsNotIncludingTemplatesData(state, data) {
    state.entityDocumentsNotIncludingTemplatesData = data;
  },
  setEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData(state, data) {
    state.employeeDocumentsByEntityIdAndConfigurableDocumentIdData = data;
  },
  setEmployeeDocumentsByEntityIdAndConfigurableDocumentIdStatus(state, status) {
    state.employeeDocumentsByEntityIdAndConfigurableDocumentIdStatus = status;
  },
  setEntityDocumentsDataNotIncludingTemplatesStatus(state, status) {
    state.entityDocumentsDataNotIncludingTemplatesStatus = status;
  },
  setTemplatesFieldsDataFromEntitiesStatus(state, status) {
    state.templatesFieldsDataFromEntitiesStatus = status;
  },
  setTemplatesFieldsDataFromEntitiesData(state, data) {
    state.templatesFieldsDataFromEntitiesData = data;
  },
  setEntityRecordsForTable(state, data) {
    state.entityRecordsForTable = data;
  },
  setEntityPrimaryData(state, data) {
    state.entityPrimaryData = data;
  },
  setEntitiesDataErrors(state, data) {
    state.errors = data;
  },

  setTemplateRelatedEntities(state, data) {
    state.templateRelatedEntities = data;
  },
  setDocumentUploadToEntityProfileStatus(state, status) {
    state.documentUploadToEntityProfileStatus = status;
  },
  setDocumentUploadToEntityProfileData(state, data) {
    state.documentUploadToEntityProfileData = data;
  },
  setCustomDashboardStats(state, data) {
    state.customDashboardStats = data;
  },
  setCustomDashboardPieStats(state, data) {
    state.customDashboardPieStats = data;
  },
  setAllCalendarsData(state, data) {
    state.allCalendarsData = data;
  },
  resetData: (state) => {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  setfetchCheckin(state, data) {
    state.allsetfetchCheckin = data;
  },
  setEntityShowFields(state, data) {
    state.entityShowFields = data;
  },
  setSentEntityNotificationStatus(state, data) {
    state.sentEntityNotificationStatus = data;
  },
  setSentEntityDataNotificationStatus(state, data) {
    state.sentEntityDataNotificationStatus = data;
  },
  setSentEntityNotificationStatusErrors(state, data) {
    state.sentEntityNotificationStatusErrors = data;
  },
  setBulkUpdateStatus(state, data) {
    state.bulkUpdateStatus = data;
  },
  setEntityDataExists(state, data) {
    state.entityDataExists = data;
  },
  setEntityViewsData(state, data) {
    state.entityViewsData = data;
  },
  //entityByNames
  setEntityByNames(state, data){
    state.entityByNames = data
  },
  setEntityDataByIdError(state, error){
    state.entityDataByIdError = error
  },
  setEntityFieldRelatedEntities(state, data){
    state.entityFieldRelatedEntities = data
  },
  setEntityVariableFieldsInputTypes(state, data){
    state.entityFieldsInputTypes = data
  },
  setEntityPrimaryLabels(state, data){
    state.entityPrimaryLabels = data;
  },
  setAllEntitiesInTemplate(state, data){
    state.allEntitiesInTemplate = data;
  }
};

const actions = {
  updatePaginationNumber(context, page) {
    context.commit("setCurrentPage", page);
  },

  updatePageSizeNumber(context, page) {
    context.commit("setPageSize", page);
  },

  reset: function (context) {
    context.commit("entities/resetData", null, {
      root: true,
    });
  },
  createNewEntity: async ({ commit }, params) => {
    try {
      commit("setEntityCreatedData", null);
      commit("setEntityErrors", null);
      commit("setEntityCreatedStatus", false);
      let response = await axios.post("/entities/create", params);
      commit("setEntityCreatedData", response.data.data);
      commit("setEntityCreatedStatus", true);
    } catch (err) {
      if (err.response && err.response.data) {
        commit("setEntityErrors", err.response.data.message);
      }
    }
  },
  //entityByNames
  fetchAllEntityNames:async(context, params)=>{
    console.log(params)
    try{
      context.commit("setEntityByNames", null);
      let response = await axios.post(`/entities/getEntityNames`,params
      );
      context.commit("setEntityByNames", response.data.data);
    }catch(err){
      console.log(err);
    }
  },
  //export entity data
  entityDataExport: async (context, params) => {
    try {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      context.commit("setEntityDataExportStatus", false);
      await axios
        .post(`/export/entities-data/${params.id}?tz=${tz}`, params, {
          responseType: "blob",
        })
        .then((response) => {
          console.log("response from export api", response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${params.entityName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
      context.commit("setEntityDataExportStatus", true);
    } catch (err) {
      console.log(err);
    }
  },
  entityDataExportPDF: async (context, params) => {
    try {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      context.commit("setEntityDataExportPDFStatus", null);
      let response = await axios.post(`/export/entities-data/pdf/${params.id}?tz=${tz}`, params)
      context.commit("setEntityDataExportPDFStatus", response.data);
    } catch (err) {
      context.commit("setEntityDataExportPDFStatus",false)
      console.log(err);
    }
  },
  entityViewsExport : async (context, params) => {
    try{
      context.commit("setEntityViewsPDFExport",null)
      let response = await axios.post('/entity-views/export-pdf',params)
      context.commit('setEntityViewsPDFExport',response.data)
    }
    catch(err){
      context.commit('setEntityViewsPDFExport',false)
      console.log("err",err)
    }
  },
  allEntitiesDataExport: async (context, params) => {
    try {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      context.commit("setAllEntityDataExportStatus", false);
      await axios
        .post(`export/all-entities-data?tz=${tz}`, params, {
          responseType: "blob",
        })
        .then((response) => {
          console.log("response from export api", response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          console.log("url", url);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${params.companyName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
      context.commit("setAllEntityDataExportStatus", true);
    } catch (err) {
      console.log(err);
    }
  },
  uploadCSVfile: async (context, params) => {
    try {
      context.commit("entities/setUploadCSVfileStatus", null, { root: true });
      const responce = await axios.put(
        `/import/upload-entities-data/${params.id}`,
        params
      );
      context.commit("entities/setUploadCSVfileStatus", responce.data, {
        root: true,
      });
    } catch (error) {
      console.log(error);
    }
  },
  storeUploadedFile: async (context, params) => {
    try {
      context.commit("entities/setstoreUploadedFileStatus", null, {
        root: true,
      });
      const responce = await axios.post(
        "/import/store-uploaded-file",
        params.data,
        { params: { name: params.fileName } }
      );
      context.commit(
        "entities/setstoreUploadedFileStatus",
        responce.data.data,
        {
          root: true,
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  getuploadedFiles: async ({ commit }, params) => {
    try {
      commit("setuploadedFilesData", null);
      let response = await axios.get(`/get/import/UploadedFiles/${params.id}`);
      commit("setuploadedFilesData", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  //updating mappedfileds
  updatemappedFields: async (context, params) => {
    let response = null;
    try {
      context.commit("entities/setupdateMappedFieldStatus", false, {
        root: true,
      });
      response = await axios.post(`/update/mapped-fields/${params.id}`, params);
      context.commit("entities/setupdateMappedFieldStatus", response.data, {
        root: true,
      });
    } catch (error) {
      console.log(error);
    }
  },

  //getting mapped fields
  getMappedFields: async ({ commit }, params) => {
    let response = null;
    try {
      // commit("setmappedFieldsData",0);
      // response=await axios.get("/get/mappedFieldsData");
      // commit("setmappedFieldsData",response.data);
      // // commit("setmappedFieldsData",response);
      commit("setmappedFieldsData", null);
      response = await axios.post("/get/mappedFieldsData", params);
      commit("setmappedFieldsData", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  //getting templates based on mapped fields
  getTemplatesByMappedFields: async ({ commit }, params) => {
    let response = null;
    try {
      commit("settemplatesByMappedFields", null);
      response = await axios.post("/get/templatesByMappedFields", params);
      commit("settemplatesByMappedFields", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  //importing mapped data

  importMappedDataVersion: async (context, params) => {
    try {
      let response = null;
      context.commit(
        "entities/setimportMappedDataStatusUpdatedVersion",
        false,
        {
          root: true,
        }
      );
      response = await axios.post("/map-entities-data", params);
      context.commit(
        "entities/setimportMappedDataStatusUpdatedVersion",
        response.data,
        {
          root: true,
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
  createRecordWithMappedData: async (context, params) => {
    try {
      let response = null;
      context.commit("entities/setCreateEntityRecordWithMappedData", false, {
        root: true,
      });
      response = await axios.post("/import-excel-data", params);
      context.commit(
        "entities/setCreateEntityRecordWithMappedData",
        response.data,
        {
          root: true,
        }
      );
    } catch (err) {
      console.log(err);
    }
  },
  fetchExcelFileHeaders: async (context, params) => {
    try {
      let response = null;
      context.commit("entities/setExcelFileHeaders", false, {
        root: true,
      });
      response = await axios.post("/get-excel-headers", params);
      context.commit("entities/setExcelFileHeaders", response.data.data, {
        root: true,
      });
    } catch (error) {
      console.log(error);
    }
  },
  fetchExcelFileHeadersApplication: async (context, params) => {
    try {
      let response = null;
      context.commit("entities/setExcelFileHeadersApplication", false, {
        root: true,
      });
      response = await axios.post("/get-excel-headers-app", params);
      context.commit(
        "entities/setExcelFileHeadersApplication",
        response.data.data,
        { root: true }
      );
    } catch (err) {
      console.log(err);
    }
  },
  entitydataBulkDelete: async ({ commit }, params) => {
    try {
      commit("setEntitydataBulkDeleteErrors", null);
      commit("setentitydataBulkDeleteStatus", null);
      let response = await axios.post("/entities-data/delete-many", params);
      commit("setentitydataBulkDeleteStatus", response.data);
    } catch (err) {
      if (err.response && err.response.data) {
        commit("setEntitydataBulkDeleteErrors", err.response.data.message);
      }
    }
  },

  fetchEntities: async ({ commit }, params) => {
    let response = null;
    try {
      commit("setAllEntities", 0);
      response = await axios.get("/entities", {
        params,
      });
      commit("setAllEntities", response.data);
    } catch (err) {
      // commit('setNotificationCountUnOpened', 0);
      // commit('setChatCountUnOpened', 0);
    }
  },
  fetchEntityById: async ({ commit }, data) => {
    try {
      commit("setEntityDataById", null);
      let response = await axios.get(`/entities/${data.entity_id}`, { data });
      commit("setEntityDataById", response.data.data);
    } catch (err) {
      commit("setEntityDataById", null);
      if (err?.response?.data?.message) {
        commit("setEntityDataByIdError", err.response.data.message)
      }
      console.log(err);
    }
  },

  fetchChildEntityById: async ({ commit }, id) => {
    try {
      commit("setChildEntityDataById", null);
      let response = await axios.get(`/entities/${id}`);
      commit("setChildEntityDataById", response.data.data);
    } catch (err) {
      commit("setChildEntityDataById", null);
      console.log(err);
    }
  },
  fetchEntityByIdForEntityField: async (context, params) => {
    try {
      let id = params.entity_id;
      delete params.id;
      context.commit("setEntityById", null);
      let response = await axios.get(`/entities/${id}`, {
        params,
      });
      context.commit("setEntityById", response.data.data);
    } catch (err) {
      context.commit("setEntityById", null);
      console.log(err);
    }
  },
  updateEntityById: async ({ commit }, data) => {
    try {
      commit("setEntityDataUpdateStatus", false);
      await axios.put(`/entities/${data.id}`, data);
      commit("setEntityDataUpdateStatus", true);
    } catch (err) {
      if (err.response && err.response.data) {
        commit("setEntityErrors", err.response.data.message);
      }
    }
  },
  async deleteEntity({ commit }, params) {
    try {
      commit("setEntityDeleteStatus", false);

      await axios.delete("entities/" + params.id);
      commit("setEntityDeleteStatus", true);
    } catch (error) {
      if (error.response && error.response.data) {
        commit("setEntityDeleteError", error.response.data.message);
      }
    }
  },

  // fetchEntitiesByQuery: async ({
  //   commit
  // }, params) => {
  //   try {
  //     commit("setEntityDataByQuery", null);
  //     let response = await axios.post("/entities", params);
  //     commit("setEntityDataByQuery", response.data.data);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },
  fetchEntityPrimaryDataById: async ({ commit }, params) => {
    try {
      commit("setEntityPrimaryData", null);
      const response = await axios.get(`/entity-data/${params.id}`, { params });
      commit("setEntityPrimaryData", response.data.data);
    } catch (err) {
      console.log(err);
    }
  },
  fetchEntityDataByIdWithOutAccessToken: async ({ commit }, params) => {
    try {
      commit("setEntityPrimaryData", null);
      const response = await axios.get(`/oa_entitydata/${params.id}`, { params });
      commit("setEntityPrimaryData", response.data.data);
    } catch (err) {
      console.log(err);
    }
  },

  fetchEntityDataByQuery: async ({ commit }, params) => {
    try {
      commit("setEntitiesDataByQuery", null);
      let response = await axios.post("/entities-data", params);
      commit("setEntitiesDataByQuery", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  fetchEntitiesDataByQuery: async ({ commit }, params) => {
    try {
      commit("setEntitiesDatasByQuery", null);
      let response = await axios.post("/entities-datas", params);
      commit("setEntitiesDatasByQuery", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  callCronForAutoLogout: async ({commit}, params) =>{
    try{
      commit("setCronForAutoLogout", null);
      let response = await axios.post("/cron/create-cron-job",params);
      commit("setCronForAutoLogout",response.data.data);
    }catch(error){
      console.log(error);
    }
  },
  fetchEntitiesByQuery: async ({ commit }, params) => {
    try {
      commit("setEntityDataByQuery", null);
      let response = await axios.post("/entities", params);
      commit("setEntityDataByQuery", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  errorResponse: (context, errorResponse) => {
    let errorStatusCode = errorResponse.status;
    let errorData = {};
    switch (errorStatusCode) {
      case 422:
        var errors = errorResponse.data.errors;
        errors.details.forEach((detail) => {
          if (detail.key == "files") {
            if (!errorData[detail.path]) {
              errorData[detail.path] = detail.message;
            }
          } else {
            if (!errorData[detail.key]) {
              errorData[detail.key] = detail.message;
            }
          }
        });
        break;
      case 401:
      case 403:
        errorData["critical_error"] = errorResponse.data.message;

        break;

      default:
        errorData["critical_error"] = errorResponse.data.message;
        break;
    }
    return errorData;
  },
  fetchEntityDataById: async (context, data) => {
    try {
      let params = data.params;
      context.commit("entities/setEntityDataByEntityId", [], {
        root: true,
      });
      let response = await axios.get(`/entities/${data.id}/list`, {
        params,
      });
      context.commit("entities/setEntityDataByEntityId", response.data, {
        root: true,
      });
    } catch (err) {
      console.log(err);
    }
  },
  fetchEntityRecordsForTable: async (context, data) => {
    try {
      context.commit("entities/setEntityRecordsForTable", false, {
        root: true,
      });
      context.commit("entities/setEntitiesDataErrors", {}, { root: true });
      let response = await axios.post(`/entities-data/entity/data`, data);
      context.commit("entities/setEntityRecordsForTable", response.data, {
        root: true,
      });
    } catch (error) {
      context.commit("entities/setEntityRecordsForTable", false, {
        root: true,
      });
      if (error.response) {
        const errors = await context.dispatch(
          "entities/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("entities/setEntitiesDataErrors", errors, {
          root: true,
        });
      }
    }
  },
  fetchEntitiesDocuments: async ({ commit }, params) => {
    console.log(params);
    try {
      commit("setEntityDocumentsData", null);
      commit("setEntityDocumentsDataStatus", false);
      let response = await axios.get(
        `/entities/getDocuments/${params.entity_id}/${params.entity_data_id}`,
        {
          params,
        }
      );
      commit("setEntityDocumentsDataStatus", true);
      commit("setEntityDocumentsData", response.data.data, true);
    } catch (err) {
      console.log(err);
    }
  },
  fetchEntitiesDocumentsNotIncludingTemplates: async ({ commit }, params) => {
    console.log(params);
    try {
      commit("setEntityDocumentsNotIncludingTemplatesData", null);
      commit("setEntityDocumentsDataNotIncludingTemplatesStatus", false);
      let response = await axios.get(
        `/entities/fetchEntityDocumentsNotIncludeTemplates/${params.entity_id}/${params.entity_data_id}`,
        {
          params,
        }
      );
      console.log(response.data);
      commit("setEntityDocumentsDataNotIncludingTemplatesStatus", true);
      commit(
        "setEntityDocumentsNotIncludingTemplatesData",
        response.data,
        true
      );
    } catch (err) {
      console.log(err);
    }
  },
  getEmployeeDocumentsByEntityIdAndConfigurableDocumentId: async (
    { commit },
    params
  ) => {
    try {
      commit(
        "setEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData",
        null
      );
      commit(
        "setEmployeeDocumentsByEntityIdAndConfigurableDocumentIdStatus",
        false
      );
      let response = await axios.get(
        `/entities/getEmployeeDocumentsByEntityIdAndConfigurableDocumentId/${params.entity_id}/${params.entity_data_id}`,
        {
          params,
        }
      );
      commit(
        "setEmployeeDocumentsByEntityIdAndConfigurableDocumentIdStatus",
        true
      );
      commit(
        "setEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData",
        response.data,
        true
      );
    } catch (err) {
      console.log(err);
    }
  },
  fetchTemplatesFieldsDataFromEntities: async ({ commit }, params) => {
    try {
      commit("setTemplatesFieldsDataFromEntitiesData", null);
      commit("setTemplatesFieldsDataFromEntitiesStatus", false);
      let response = await axios.get(
        `/entities/TemplatesFieldsDataFromEntities/${params.entity_id}/${params.entity_data_id}`,
        {
          params,
        }
      );
      commit("setTemplatesFieldsDataFromEntitiesStatus", true);
      commit(
        "setTemplatesFieldsDataFromEntitiesData",
        response.data.data,
        true
      );
    } catch (err) {
      console.log(err);
    }
  },
  fetchCompanyEntity: async ({ commit }) => {
    try {
      commit("setCompanyEntity", null);
      let response = await axios.get(`/entities/getCompany/CompanyId`);
      commit("setCompanyEntity", response.data.data);
    } catch (err) {
      commit("setCompanyEntity", null);
      console.log(err);
    }
  },
  fetchCompanyEntityDetails: async ({ commit }) => {
    try {
      commit("setCompanyEntityDetails", null);
      const response = await axios.get("/company-entity");
      commit("setCompanyEntityDetails", response.data.data);
    } catch (err) {
      console.log(err);
    }
  },
  addCompanyEntity: async ({ commit }, params) => {
    try {
      commit("setEntityErrors", null);
      commit("setAddEntityToCompanyStatus", false);
      const response = await axios.post("/entities/createCompany", params);
      console.log("params", response);
      commit("setAddEntityToCompanyStatus", true);
      commit("setCompanyEntity", response.data.entity);
    } catch (err) {
      commit("setEntityErrors", err);
      console.log(err);
    }
  },

  getEntitiesByTemplates: async ({ commit }, params) => {
    try {
      commit("setEntityErrors", null);
      commit("setTemplateRelatedEntities", []);
      const response = await axios.post("/entities/check-entities", params);
      commit("setTemplateRelatedEntities", response.data.data);
    } catch (err) {
      commit("setEntityErrors", err);
      console.log(err);
    }
  },
  uploadDocumentToEntityProfile: async ({ commit }, params) => {
    try {
      commit("setDocumentUploadToEntityProfileStatus", false);
      commit("setDocumentUploadToEntityProfileData", []);
      const response = await axios.post("/entities/uploadDocuments", params);
      commit("setDocumentUploadToEntityProfileStatus", true);
      commit("setDocumentUploadToEntityProfileData", response.data.data, true);
    } catch (err) {
      console.log(err);
    }
  },
  fetchCustomDashboardStats: async ({ commit }, params) => {
    try {
      commit("setCustomDashboardStats", null);
      const response = await axios.post("/entities-data/count", params);
      commit("setCustomDashboardStats", response.data.data);
    } catch (err) {
      commit("setCustomDashboardStats", null);
    }
  },
  fetchCustomDashboardPieStats: async ({ commit }, params) => {
    try {
      commit("setCustomDashboardPieStats", null);
      const response = await axios.post("/entities-data/charts", params);
      commit("setCustomDashboardPieStats", response.data);
    } catch (err) {
      commit("setCustomDashboardPieStats", null);
    }
  },
  addsigninTime: async ({ commit }, params) => {
    try {
      commit(
        "setsigninTimeDatastatus",
        {},
        {
          root: true,
        }
      );
      let response = await axios.post(
        "/entities-data/createSigninTime",
        params
      );
      commit("setsigninTimeDatastatus", response.data.data, {
        root: true,
      });
    } catch (err) {
      commit(
        "setsigninTimeDatastatus",
        {},
        {
          root: true,
        }
      );
    }
  },
  fetchCheckin: async (context, params) => {
    try {
      // context.commit("setfetchCheckin", null);
      context.commit("setfetchCheckin", {});
      const response = await axios.get("/entities-data/fetchCheckin", params);
      console.log("response", response);
      context.commit("setfetchCheckin", response.data.data);
    } catch (errors) {
      context.commit("setfetchCheckin", {});
      console.log(errors);
    }
  },
  fetchCalendarData: async (context, params) => {
    try {
      context.commit("setAllCalendarsData", null);
      const response = await axios.post("/getCalender/calender", params);
      context.commit("setAllCalendarsData", response.data.data);
    } catch (errors) {
      console.log(errors);
    }
  },
  //Notify all entity contacts
  sendNotificationToAllUsers: async (context, params) => {
    try {
      context.commit("setSentEntityNotificationStatus", false);
      await axios.post("/entities/send-notification", params);
      context.commit("setSentEntityNotificationStatus", true);
    } catch (err) {
      context.commit("setSentEntityNotificationStatusErrors", err.response.data,true);
    }
  },
  //Notify all entity data contacts
  sendNotificationToEntityDatausers: async (context, params) => {
    try {
      context.commit("setSentEntityDataNotificationStatus", false);
      await axios.post("/entities-data/send-notification", params);
      context.commit("setSentEntityDataNotificationStatus", true);
    } catch (errors) {
      context.commit("setSentEntityNotificationStatusErrors", errors.response.data.message,true);
    }
  },
  bulkUpdateEntityData: async (context, params) => {
    try {
      context.commit("setBulkUpdateStatus", false);
      await axios.post("entities-data/bulk-update", params);
      context.commit("setBulkUpdateStatus", true);
    } catch (err) {
      console.log(err);
    }
  },
  checkEntityDataByFilters: async ({ commit }, params) => {
    try {
      commit("setEntityDataExists", null);
      const response = await axios.post(`/entities-data/check`, params);
      commit("setEntityDataExists", response.data.data);
    } catch (err) {
      console.log(err);
    }
  },
  fetchEntityViewsData: async (context, data) => {
    try {
      context.commit("entities/setEntityViewsData", false, {
        root: true,
      });
      context.commit("entities/setEntitiesDataErrors", {}, { root: true });
      let response = await axios.post(`/entities-data/entity/view/data`, data);
      context.commit("entities/setEntityViewsData", response.data, {
        root: true,
      });
    } catch (error) {
      context.commit("entities/setEntityViewsData", false, {
        root: true,
      });
      if (error.response) {
        const errors = await context.dispatch(
          "entities/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("entities/setEntitiesDataErrors", errors, {
          root: true,
        });
      }
    }
  },
  fetchEntityFieldRelatedEntities: async ({ commit }, id) => {
    try {
      commit("setEntityFieldRelatedEntities", null);
      const response = await axios.get(`/entities/related/`+id);
      commit("setEntityFieldRelatedEntities", response.data.data);
    } catch (err) {
      console.log(err);
    }
  },
  fetchEntityFieldsInputTypes: async (context, params)=> {
    try {
      context.commit("entities/setEntityVariableFieldsInputTypes", false, {
        root: true,
      });
      context.commit("entities/setEntitiesDataErrors", {}, { root: true });
      let response = await axios.post(`/get-entities-input-types`, params);
      context.commit("entities/setEntityVariableFieldsInputTypes", response.data.data, {
        root: true,
      });
    } catch (error) {
      context.commit("entities/setEntityVariableFieldsInputTypes", false, {
        root: true,
      });
      if (error.response) {
        const errors = await context.dispatch(
          "entities/errorResponse",
          error.response,
          { root: true }
        );
        context.commit("entities/setEntitiesDataErrors", errors, {
          root: true,
        });
      }
    }
  },
  // fetchEntityDataEmails: async(context, data) => {
  //   try{
  //     context.commit('entities/setEntityDataEmails',false,{root : true})
  //     let response = await axios.get(``)
  //   }
  //   catch(err){
  //     throw err
  //   }
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
