import AuthMiddleware from "../middleware/auth"

const PublicLayout = () => import ('@/views/WebIntegrations.vue');
const Dashboard = () => import('@/views/Dashboard');
const EntitiesList = () => import('@/components/entity/EntitiesList');
const ViewEntityData = () => import('@/components/entity/ViewEntityData');
const EntitiesViewsList = () => import('@/components/entity/EntityCalculationViews');
const EntitiesViewEdit = () => import('@/components/entity/EntityViewsEdit');
const EntityViews = () => import('@/components/entity/EntityViews');
const createEntityFromExcel = () => import('@/components/entity/createEntityFromExcel');
const AddEditEntity = () => import('@/components/entity/AddEditEntity');
const ViewEntity = () => import("@/components/entity/ViewEntity");
const EntityDetailedView = () => import('@/components/entity/EntityDetailedCardView.vue');
const UserTemplatesData = () => import('@/views/users/UserTemplatesData');
const EntityExecute = () => import("@/components/entity/entityExecute");
const EntityExecuteStep = () => import("@/components/entity/entityExecuteSteps");
const PreviewEntityData = () => import("@/components/entity/preview");
const PreviewEntityDataStep = () => import("@/components/entity/previewStep");
const customDashboardList = () => import('@/components/customDashboard/customDashboardList.vue');
const customEntityMention = () => import('@/components/widgets/entityMention/index.vue');
const Roles = () => import('@/components/Roles/Roles');
const RequestDocuments = () => import('@/components/requestDocuments/AllRequestDocuments');
const RequestDocumentsSelectCardType = () => import('@/components/requestDocuments/SelectCardType');
const RequestDocumentDraft = () => import('@/components/requestDocuments/RequestDocumentDraft');
const UploadRequestDocumentPreference = () => import('@/components/requestDocuments/UploadRequestDocumentPreference');
const GlobalVariablesList = () => import('../views/globalVariables/GlobalVariablesList');
const GlobalVariableAdd = () => import('../views/globalVariables/GlobalVariableAdd');
const MenuManagement = () => import('../views/menuManagement');
const iFrameSignin =() =>import ('../views/iFrameSignin.vue');
const EntityGroupsDashboard = () => import('@/components/Groups/EntityGroups');
const EntityGroupPreview = () => import('../views/entities/EntityGroupPreview');
const EntityGroupPreviewEntityData = () => import('../views/entities/EntityGroupPreviewEntityData');
const FormBuilderList = () => import('@/views/formBuilders/FormBuildersList');
const FormBuilderUserData = () => import('@/views/formBuilders/FormBuilderUserData');
const FormbuilderView = () => import('@/components/formBuilders/formbuilderView.vue');
const FormbuilderViewStep = () => import('@/components/formBuilders/formbuilderViewStep.vue');
const FormbuilderDataList = () => import('@/components/formBuilders/formbuilderTemplateDataList');
const ApprovalFormsView = () => import('@/components/formBuilders/approvalFormsView.vue');
const AllTemplates = () => import('@/components/templates/AllTemplates');
const WorkflowList =() =>import ('../components/workFlows/templateWorkflowList');
const WorkflowDataList =() =>import ('../components/workFlows/templateWorkflowDataList');
const Documents = () => import('@/components/companyDocuments/AllDocuments');
const SignDocuments = () => import('@/components/SignDocuments');
const UploadDocumentPreference = () => import('@/components/companyDocuments/UploadDocumentPreference');
const UploadTemplatePreference = () => import('@/components/companyDocuments/UploadTemplatePreference');
const Profile = () => import('@/views/account/Profile');
const PersonalInformation = () => import("@/components/account/PersonalInformation");
const Brandings = () => import("@/components/account/Branding");
const CompanyUsers = () => import("@/components/account/CompanyUsers");
const Signature = () => import("@/components/account/Signature");
const Initial = () => import("@/components/account/Initial");
const UpdatePassword = () => import("@/components/account/UpdatePassword");
const Permissions = () => import("@/components/account/Permissions");
const SubscriptionInformation = () => import("@/components/account/Subscriptions.vue");
const plansList = () => import("@/components/account/Plans.vue");
const StripePaymentStatus = () => import("@/components/payment/payment-status.vue");
const PaymentIntegrations = () => import("@/components/Configuration/Payment/PaymentIntegrations.vue");
const PaymentsCards = () => import("../components/Configuration/Payment/Payment.vue");
const paymentStatus = () => import("@/components/payment/payment.vue");
const ApplicationUserDashboards = () => import('@/components/entity/ApplicationUserCustomDashboardList.vue');
const PageCustomDashboard = () => import('@/components/applicationUsers/PageCustomDashboard');
const entityDashboardConfig = () => import('@/components/customDashboard/customDashboardConfig.vue');
const Profilesettings = () => import('@/views/account/Profilesettings');
const DocumentExpirationReminders = () => import("@/components/account/DocumentExpirationReminders");
const ApplicationSettings = () => import("@/components/account/ApplicationSettings");
const Notifications = () => import("@/components/account/Notifications");
const Statistics = () => import("@/components/account/dashboardStats");
const GlobalFilters = () => import("@/components/account/GlobalFilters");
const Branding = () => import("@/views/account/Branding");
const ApplicationList = () => import('@/components/application/applicationsList.vue');
const PublishedAppsList = () => import('@/components/application/publishedApps.vue');
const ApprovalAppsList = () => import('@/components/application/appApprovals.vue');
const UploadAppsSteps = () => import('./../components/application/uploadAppSteps.vue');
const InstalledAppList = () => import('@/components/application/installedApps.vue');
const InstallApp = () => import("./../components/application/employeeManagementPopUp.vue");
const ListAllSteps = () => import("./../components/application/step-container.vue");
const CreateApplicationFromExcel = () => import("./../components/application/createApplicationFromExcel.vue");
const Contacts = () => import('@/components/contacts/Contacts');
const AddContacts = () => import('@/components/contacts/AddContact');
const UserDocuments = () => import('@/components/contacts/UserDocuments');
const ContactTypes = () => import('@/components/contacts/ContactTypes');
const sendDocumentOnMail = () => import('@/components/companyDocuments/sendDocumentOnMail');
const sendTemplateOnMail = () => import('@/components/companyDocuments/sendTemplateOnMail');
const CompanyUserList = () => import('@/components/company/CompanyUsers');
const TemplateList = () => import('../views/templates/TemplatesList');
const NewEmailTemplate = () => import('@/components/emailTemplates/newEmailTemplate.vue');
const EmailTemplatesList = () => import('@/components/emailTemplates/emailTemplatesList.vue');
const NewEmailHeaderFooter = () => import('@/components/emailTemplates/newHeaderFooter.vue')

export default[
    {
        path: '/if/:ssoAuth',
        component: PublicLayout,
        children: [
            {
                path: '',
                component: Dashboard,
            },
            {
                path: '/if/:ssoAuth/dashboard',
                component: Dashboard,
            },
            {
                path: "/if/:ssoAuth/entity",
                name: "Entity List",
                component: EntitiesList,
                meta: {
                    title: 'Entity List'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id",
                name: "EntityData1",
                component: ViewEntityData,
                meta: {
                    title: 'View Entity Data'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views",
                name: "EntityViewsList",
                component: EntitiesViewsList,
                meta: {
                    title: 'Entity views list'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views/add",
                name: "EntityViewAdd",
                component: EntitiesViewEdit,
                meta: {
                    title: 'Entity view add'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views/edit/:entity_view_id",
                name: "EntityViewEdit",
                component: EntitiesViewEdit,
                meta: {
                    title: 'Entity view edit'
                }
            },
            {
                path: "/if/:ssoAuth/entity-views/view/:entity_view_id",
                name: "EntityViews",
                component: EntityViews,
                meta: {
                    title: 'Entity views'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/excel/add",
                name: "entity-from-excel",
                component: createEntityFromExcel,
                meta: {
                    title: 'Create Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/add",
                name: "Create Entity",
                component: AddEditEntity,
                meta: {
                    title: 'Create Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/edit",
                name: "Edit Entity1",
                component: AddEditEntity,
                meta: {
                    title: 'Edit Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id/view",
                name: "View Entity",
                component: ViewEntity,
                meta: {
                    title: 'View Entity'
                }
            },
            {
                path: "/if/:ssoAuth/entity/:entity_id",
                name: "EntityData",
                component: ViewEntityData,
                meta: {
                    title: 'View Entity Data'
                }
            },
            {
                path: "/if/:ssoAuth/entity/view/:entity_id",
                name: "EntityDetailedView2",
                component: EntityDetailedView,
                meta: {
                    title: "Entity data view"
                }
            },
            {
                path: "/if/:ssoAuth/entity/edit/:entity_id",
                name: "EntityDetailedEdit2",
                component: EntityDetailedView,
                meta: {
                    title: "Entity data edit"
                }
            },
            {
                path: "/if/:ssoAuth/entity/:user_id/:template_code",
                name: "TemplatesData",
                component: UserTemplatesData,
                meta: {
                    title: 'TemplatesData'
                }
            },
            {
                path: "/if/:ssoAuth/entity-execute/:entity_id",
                name: "EntityExecute",
                component: EntityExecute,
                children: [{
                    path: '/entity-execute/:entity_id/:template_code',
                    name: "EntityExecuteStep",
                    component: EntityExecuteStep,
                }]
            },
            {
                path: "/if/:ssoAuth/entity_data_view/:entity_id",
                name: "PreviewEntityData",
                component: PreviewEntityData,
                children: [{
                    path: '/if/:ssoAuth/entity_data_view/:entity_id/:template_code',
                    name: 'PreviewEntityDataStep',
                    component: PreviewEntityDataStep
                }]
            },
            {
                path: "/if/:ssoAuth/entityData-Edit/:entity_id",
                name: "EntityDataEdit",
                component: EntityExecute,
                children: [{
                    path: '/if/:ssoAuth/entityData-Edit/:entity_id/:template_code',
                    name: "EntityDataEditStep",
                    component: EntityExecuteStep,
                }]
            },
              //custom dashboard
        {
            path: '/if/:ssoAuth/custom/dashboard-list',
            component: customDashboardList,
            name: 'CustomDashboardList'
        },
        {
            path: '/if/:ssoAuth/custom/CustomEntityMention',
            component: customEntityMention,
            name: 'CustomEntitySuggestion'
        },
        //Roles
        {
            path: "/if/:ssoAuth/roles",
            component: Roles,
            name: "Roles",
        },

        {
            path: "/if/:ssoAuth/request-documents/all/:card_type?",
            name: "RequestDocuments",
            component: RequestDocuments,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/select-card-type/:contact_id?",
            name: "RequestDocumentsSelectCardType",
            component: RequestDocumentsSelectCardType,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/upload",
            name: "upload-request-documents_",
            component: UploadRequestDocumentPreference,
            meta: {
                title: 'Upload Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/:request_document_id/edit",
            name: "request-documents-edit",
            component: RequestDocumentDraft,
            // beforeEnter: AuthMiddleware.user
            meta: {
                title: 'Request Documents Edit'
            }
        },
        //Global Variables
        {
            path: "/if/:ssoAuth/global-variables",
            component: GlobalVariablesList,
            name: "GlobalVariables",

        },
        {
            path: "/if/:ssoAuth/global-variables/add",
            component: GlobalVariableAdd,

        },
        {
            path: "/if/:ssoAuth/global-variables/view/:global_variable_id",
            component: GlobalVariableAdd,
            name: "UpdateGlobalVariable",

        },
        {
            path: "/if/:ssoAuth/menu-management",
            name: "menuManagement",
            component: MenuManagement,
        },
        {
            path: "/if/:ssoAuth/iFrameSignin",
            name: "iFrameSignin",
            component: iFrameSignin,
        },
        {
            path: "/if/:ssoAuth/company-entity-groups",
            name: "EntityGroupsDashboard",
            component: EntityGroupsDashboard,

        },
        {
            path: "/if/:ssoAuth/company-entity-groups/:group_id/preview",
            name: "EntityGroupPreview",
            component: EntityGroupPreview,
            beforeEnter: AuthMiddleware.user,
            children: [{
                path: ":entity_id",
                component: EntityGroupPreviewEntityData,
                name: "EntityGroupPreviewEntityData",
                beforeEnter: AuthMiddleware.user
            },
            ]
        },
        {
            path: '/if/:ssoAuth/formBuilders',
            name: 'FormBuilderList',
            component: FormBuilderList,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: '/if/:ssoAuth/fbd/:formbuilder_code',
            name: 'FormBuilderUserData',
            component: FormBuilderUserData,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: "/if/:ssoAuth/fbd/:formbuilder_code/data-list",
            name: "FormbuilderDataList",
            component: FormbuilderDataList,
            beforeEnter: AuthMiddleware.user,
        },
        {
            path: '/if/:ssoAuth/forms/approval-forms',
            name: 'approval-forms',
            component: ApprovalFormsView,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: "/if/:ssoAuth/fb/:formbuilder_code/:formbuilder_data_id",
            name: "FormbuilderView",
            component: FormbuilderView,
            beforeEnter: AuthMiddleware.userOrApplicationUser,
            children: [{
                path: '/if/:ssoAuth/fb/view/:formbuilder_code/:template_code/:formbuilder_data_id',
                name: "FormbuilderViewStep",
                component: FormbuilderViewStep,
                beforeEnter: AuthMiddleware.userOrApplicationUser,
            },
            ]
        }, {
            path: "/if/:ssoAuth/fb/:formbuilder_code/:formbuilder_data_id",
            name: "FormbuilderEdit",
            component: FormbuilderView,
            beforeEnter: AuthMiddleware.userOrApplicationUser,
            children: [{
                path: '/if/:ssoAuth/fb/edit/:formbuilder_code/:template_code/:formbuilder_data_id',
                name: "FormbuilderEditStep",
                component: FormbuilderViewStep,
                beforeEnter: AuthMiddleware.userOrApplicationUser,
            }
            ]
        },
        {
            path: "/if/:ssoAuth/templates",
            name: "Templates",
            component: AllTemplates,
            // beforeEnter: AuthMiddleware.user,
            meta: {
                title: 'Templates'
            }
        },
        {
            path: "/if/:ssoAuth/workflows",
            name: "Workflows",
            component: WorkflowList,
            meta: {
                title: 'Workflows'
            }
        },
        {
            path: "/if/:ssoAuth/workflow/:workflow_id",
            name: "Workflows-Data",
            component: WorkflowDataList,
            meta: {
                title: 'Workflow Data'
            }
        },
        {
            path: "/if/:ssoAuth/documents/all/:contact_id?",
            name: "Documents",
            component: Documents,
            meta: {
                title: 'Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/all/:card_type?",
            name: "RequestDocuments_",
            component: RequestDocuments,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/select-card-type/:contact_id?",
            name: "RequestDocumentsSelectCardType_",
            component: RequestDocumentsSelectCardType,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/documents-signed",
            name: "SignDocuments",
            component: SignDocuments,
            meta: {
                title: 'Signed Documents'
            }
        },
        {
            path: "/if/:ssoAuth/documents/upload",
            name: "upload-documents",
            component: UploadDocumentPreference,
            meta: {
                title: 'Upload Documents'
            }
        },
        {
            path: "/if/:ssoAuth/request-documents/upload",
            name: "upload-request-documents",
            component: UploadRequestDocumentPreference,
            meta: {
                title: 'Upload Request Documents'
            }
        },
        {
            path: "/if/:ssoAuth/templates/upload",
            name: "upload-templates",
            component: UploadTemplatePreference,
            meta: {
                title: 'Upload Templates'
            }
        },
        // Profile Routes
        {
            path: '/if/:ssoAuth/profile',
            name: "profile",
            component: Profile,
            children: [{
                path: '/if/:ssoAuth/profile/personal-information',
                name: 'personal-information',
                component: PersonalInformation,
                meta: {
                    title: 'Personal Information'
                },
                alias: '/profile',
            },
            {
                path: '/if/:ssoAuth/profile/change-password',
                name: 'change-password',
                component: UpdatePassword,
                meta: {
                    title: 'Change Password'
                }
            },
            {
                path: '/if/:ssoAuth/profile/manage-permissions',
                name: 'manage-permissions',
                component: Permissions,
                meta: {
                    title: 'Manage Permissions'
                }
            },
            {
                path: '/if/:ssoAuth/profile/signature',
                name: 'signature',
                component: Signature,
                meta: {
                    title: 'Signature'
                }
            },
            {
                path: '/if/:ssoAuth/profile/initial',
                name: 'initial',
                component: Initial,
                meta: {
                    title: 'Initial'
                }
            },
            {
                path: '/if/:ssoAuth/profile/subscription',
                name: 'subscription',
                component: SubscriptionInformation,
                // component:PaymentGateway,
                meta: {
                    title: 'Subscription'
                }
            },
            {
                path: '/if/:ssoAuth/profile/companyUsers',
                name: 'companyUsers',
                component: CompanyUsers,
                meta: {
                    title: 'Company Users'
                }
            },
            {
                path: '/if/:ssoAuth/profile/companyProfile',
                name: 'companyProfile',
                component: Brandings,
                meta: {
                    title: 'Company Profile'
                }
            },

            ]
        },

        {
            path: "/if/:ssoAuth/plans-list",
            name: "Plans",
            component: plansList,
            meta: {
                title: 'E-signs plans List'
            }
        },
        {
            path: '/if/:ssoAuth/payments-status',
            name: 'Stripe Payment Status',
            component: StripePaymentStatus,
            meta: {
                title: 'stripe payment info'
            }
        },
        {
            path: '/if/:ssoAuth/profile/paymentIntegration',
            name: 'pay-integrations',
            component: PaymentIntegrations,
            meta: {
                title: 'Subscription'
            }
            //PaymentIntegrations
        },
        {
            path: '/if/:ssoAuth/profile/paymentsCards',
            name: 'integrations',
            component: PaymentsCards,
            meta: {
                title: 'Subscription payment'
            }
        },
        {
            path: '/if/:ssoAuth/paymentStatus',
            name: 'PaymentStatus',
            component: paymentStatus,
            meta: {
                title: 'Payment Status Info'
            }
        },
        {
            path : '/if/:ssoAuth/ap-user/custom-dashboards/:entityId',
            name : 'application-user-dashboards',
            component : ApplicationUserDashboards
        },
        {
            path : '/if/:ssoAuth/page-dashboard/:dashboardId',
            name : 'page-dashbaord',
            component : PageCustomDashboard
        },
        {
            path: '/if/:ssoAuth/ap/custom/dashboard/:entityId',
            name: 'entity-custom-dashboard-config',
            component: entityDashboardConfig,
            beforeEnter: AuthMiddleware.user
        },
        // {
        //     path: '/entity-payment-status-update',
        //     name: 'commonComponentForPayment',
        //     component: commonComponentForPayment,
        //     meta: {
        //         title: " Payment Update"
        //     }
        // },

        //profilesettings
        {
            path: '/if/:ssoAuth/profilesettings',
            name: "profilesettings",
            component: Profilesettings,
            children: [

                {
                    path: '/if/:ssoAuth/profilesettings/document-expiration-reminders',
                    name: 'expiration-reminders',
                    component: DocumentExpirationReminders,
                    meta: {
                        title: 'Document Expiration & Reminders'
                    }
                },
                {
                    path: '/if/:ssoAuth/profilesettings/application-settings',
                    name: 'application-settings',
                    component: ApplicationSettings,
                    meta: {
                        title: 'Settings'
                    }
                },
                {
                    path: '/if/:ssoAuth/profilesettings/document-notifications',
                    name: 'document-notifications',
                    component: Notifications,
                    meta: {
                        title: 'Notifications'
                    }
                },

                {
                    path: '/if/:ssoAuth/profilesettings/initial',
                    name: 'profilesettingsInitial',
                    component: Initial,
                    meta: {
                        title: 'Profile settings Initial'
                    }
                },
                {
                    path: '/if/:ssoAuth/profilesettings/dashboard-settings',
                    name: 'statistics',
                    component: Statistics,
                    meta: {
                        title: 'Dashboard Status'
                    },
                    alias: '/profilesettings',
                },



                {
                    path: '/if/:ssoAuth/profilesettings/global-filters',
                    name: 'global-filters',
                    component: GlobalFilters,
                    meta: {
                        title: 'Global Filters'
                    },

                },
            ]
        },

        {
            path: '/if/:ssoAuth/branding',
            name: "Branding",
            component: Branding,
            meta: {
                title: 'Branding'
            }
        },

        {
            path: "/if/:ssoAuth/apps",
            name: "application",
            component: ApplicationList,
            meta: {
                title: 'Applications List'
            }
        },
        {
            path: '/if/:ssoAuth/apps/published',
            name: 'PublishedAppsList',
            component: PublishedAppsList,
            meta: {
                title: 'Your published apps'
            }
        },
        {
            path: '/if/:ssoAuth/apps/approvals',
            name: 'ApprovalAppsList',
            component: ApprovalAppsList,
            meta: {
                title: 'Your published apps'
            }
        },
        {
            path: '/if/:ssoAuth/apps/installed',
            name: 'InstalledAppsList',
            component: InstalledAppList,
            meta: {
                title: 'Your installed apps'
            }
        },
        {
            path: '/if/:ssoAuth/apps/upload',
            name: 'UploadYourOwnApp',
            component: UploadAppsSteps,
            meta: {
                title: 'Upload your own app to marketplace'
            }
        },
        {
            path: 'apps/create-from-excel',
            name: "CreateYourAppFromExcel",
            component: CreateApplicationFromExcel,
            meta: {
                title: 'Create application from excel'
            }

        },
        {
            path: '/if/:ssoAuth/apps/install/info',
            name: 'InstallApplication',
            component: InstallApp,
            meta: {
                title: 'Install a application from Marketplace to your workspace'
            }
        },
        {
            path: '/if/:ssoAuth/apps/new/steps',
            name: 'CreateYourApplication',
            component: ListAllSteps,
            meta: {
                title: 'Create Your Application from scratch'
            }
        },





        // Conatct Routes
        {
            path: "/if/:ssoAuth/contacts",
            name: "contacts",
            component: Contacts,
            meta: {
                title: 'Contacts'
            }
        },
        {
            path: "/if/:ssoAuth/contacts/documents/:contact_id",
            name: "user-contacts",
            component: UserDocuments,
            meta: {
                title: 'User Documents'
            }
        },
        {
            path: "/if/:ssoAuth/contacts/add",
            name: "add-contact",
            component: AddContacts,
            meta: {
                title: 'Add Contact'
            }
        },
        {
            path: "/if/:ssoAuth/contacts/edit/:contact_id",
            name: "edit-contact",
            component: AddContacts,
            meta: {
                title: 'Edit Contact'
            }
        },
        // Contact type 
        {
            path: "/if/:ssoAuth/contact-types",
            name: "contactTypes",
            component: ContactTypes,
            meta: {
                title: 'Contact Types'
            }
        },
        {
            path: "/if/:ssoAuth/signature/documents/:status",
            name: "signature-documents",
            component: Documents,
            meta: {
                title: 'Signature Documents'
            }
        },
        {
            path: "/if/:ssoAuth/send-document/mail/:id",
            name: "send-document-mail",
            component: sendDocumentOnMail,
        },
        {
            path: "/if/:ssoAuth/send-template/mail/:id/:document_id",
            name: "send-template-mail",
            component: sendTemplateOnMail,
        },
        {
            path: "/if/:ssoAuth/company/users",
            name: "Company Users",
            component: CompanyUserList,
            alias: '',
            meta: {
                title: 'Company Users'
            }
        },
        // template Routes



        {
            path: "/if/:ssoAuth/template",
            name: "Form Template",
            component: TemplateList,
            meta: {
                title: 'Create New Template '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-template/create",
            name: "CreateEmailTemplate",
            component: NewEmailTemplate,
            meta: {
                title: 'Create New Email Template '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-template/edit/:template_id",
            name: "EditEmailTemplate",
            component: NewEmailTemplate,
            meta: {
                title: 'Edit Email Template '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-header-footer-template/create",
            name: "CreateEmailHeaderFooterTemplate",
            component: NewEmailHeaderFooter,
            meta: {
                title: 'Create New Email Header Footer '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-header-footer-template/edit/:template_id",
            name: "EditEmailHeaderFooterTemplate",
            component: NewEmailHeaderFooter,
            meta: {
                title: 'Edit Email Header Footer '
            },
            alias: '',
        },
        {
            path: "/if/:ssoAuth/email-template",
            name: "Email Templates List",
            component: EmailTemplatesList,
            meta: {
                title: 'Email Templates List'
            },
            alias: '',
        },
        ],
    },
]