import axios from "@/config/axios";
const initiateState = () => {
    return {
        IframeData: null,
        IframeUrlInfo:null,
        IframeUrLinks: null,
        IframeUrlStatus:null,
        IframeUrLinksDelete:null,
    }
}
const state = initiateState()
const getters = {
    getIframeData: state => state.IframeData,
    getIframeUrlInfo : state => state.IframeUrlInfo,
    getIframeUrLinks : state => state.IframeUrLinks,
    getIframeUrlStatus : state => state.IframeUrlStatus,
    getIframeUrLinksDelete : state => state.IframeUrLinksDelete,
}
const mutations = {
    setIframeData(state, data) {
        state.IframeData = data
    },
    setIframeUrlInfo(state, data){
        state.IframeUrlInfo = data
    },
    setIframeUrLinks(state,data){
        state.IframeUrLinks = data
    },
    setIframeUrlStatus(state,data){
        state.IframeUrlStatus =data
    },
    setIframeUrLinksDelete(state,data){
        state.IframeUrLinksDelete =data
    }
}
const actions = {
    addIframe: async (context, params) => {
        try {
            context.commit("iFrame/setIframeData",[], {
                root: true
            })
            let response = await axios.post('/iframe/create', params)
            context.commit("iFrame/setIframeData", response.data.data, {
                root: true
            })
        }
    catch (error) {
        context.commit("iFrame/setIframeData", false, {
            root: true
        })
            context.commit("iFrame/setIframeErrors", error, {
                root: true
            })
    
        }
    },
    fetchIframeUrlTypes: async (context , params) => {
        try {
            context.commit("iFrame/setIframeUrlInfo", null, {
                root: true
            })
            const response = await axios.get('/iframe/get',{params})
            context.commit("iFrame/setIframeUrlInfo", response.data.data, {
                root: true
            })
        } catch (errors) {
            context.commit("iFrame/setIframeUrlInfo", {
                data: [],
                total: 0,
                page: 0
            }, {
                root: true
            })
            console.log(errors)
        }
    },
    fetchIframeUrlLinks: async (context , params) => {
        try {
            context.commit("iFrame/setIframeUrLinks", null, {
                root: true
            })
            const response = await axios.get('/iframe/getIframeLink',{params})
            context.commit("iFrame/setIframeUrLinks", response.data.data, {
                root: true
            })
        } catch (errors) {
            context.commit("iFrame/setIframeUrLinks", {
                data: [],
                total: 0,
                page: 0
            }, {
                root: true
            })
            console.log(errors)
        }
    },
    updateIframeUrlStatus: async (context , params) => {
        try {
            context.commit("iFrame/setIframeUrlStatus", null, {
                root: true
            })
            const response = await axios.put('/iframe/updateIframeLink',params)
            context.commit("iFrame/setIframeUrlStatus", response.data.data, {
                root: true
            })
        } catch (errors) {
            context.commit("iFrame/setIframeUrlStatus", {
                data: [],
                total: 0,
                page: 0
            }, {
                root: true
            })
            console.log(errors)
        }
    },
    deleteIframeUrl : async(context , params) =>{
        try {
            context.commit("iFrame/setIframeUrLinksDelete", false, {
                root: true
            })
            context.commit("iFrame/setIframeUrLinksDelete", null, {
                root: true
            })
            await axios.delete('/iframe/deleteIframeLink/' + params.id , params)
            context.commit("iFrame/setIframeUrLinksDelete", true, {
                root: true
            })
        } catch (error) {
            context.commit("iFrame/setIframeUrLinksDelete", false, {
                root: true
            })
            if (error.response) {
                const errors = await context.dispatch("iFrame/errorResponse", error.response, {
                    root: true
                });
                context.commit("iFrame/setUserDeleteError", errors, {
                    root: true
                })
            }
        }
    }
}
export default {
    namespaced: true,
    mutations,
    getters,
    state,
    actions
}